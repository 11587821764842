
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';
import { getFullName } from '@/modules/user';
import { getEnumByName } from '@/util';
import { EmailTemplateType } from '../emailtemplate';

@Component
export default class Customer extends SubModule {
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM] {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[] {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[] {
    // E-mail route as single tab
    if (this.$route.meta && this.$route.meta.isEmailRoute === true) {
      const emailTemplateType = getEnumByName(EmailTemplateType, this.$route.params.emailTemplateType);
      return [
        {
          title: emailTemplateType ? this.$root.$t(`module.emailtemplate.enums.EmailTemplateType.${emailTemplateType}`).toString() : this.$t('email.title').toString(),
          icon: '$vuetify.icons.sendEmail',
          route: this.$route.fullPath,
        },
      ];
    }

    const activeItem = this.activeItem;
    const items: IMenuItem[] = [];
    if (activeItem) {
      if (activeItem.$isCreated()) {
        items.push(
          {
            title: getFullName(activeItem.$original, true),
            icon: `$vuetify.icons.edit`,
            route: `/customer/${activeItem.id}`,
          },
          {
            title: this.$t('languageGroups.title').toString(),
            icon: '$vuetify.icons.customerlanguagegroup',
            route: `/customer/${activeItem.id}/languagegroups`,
          },
          {
            title: this.$t('costConfiguration.title').toString(),
            icon: '$vuetify.icons.costconfiguration',
            route: `/customer/${activeItem.id}/costconfiguration`,
          },
          {
            title: this.$t('invoiceContact.title').toString(),
            icon: '$vuetify.icons.contact',
            route: `/customer/${activeItem.id}/invoicecontact`,
          },
        );
      } else {
        items.push({
          title: this.$t('form.titleNew').toString(),
          icon: `$vuetify.icons.add`,
          route: `/customer/new`,
        });
      }
    }

    return items;
  }
}
